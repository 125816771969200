import React, { useState, useEffect } from "react";
import {
  Dimensions,
  Button,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Image,
  StyleSheet,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as ImagePicker from "expo-image-picker";
import { uploadPresenter } from "../../../firebase";
import * as ImageManipulator from "expo-image-manipulator";

export const AddPresenterForm = ({ navigation }) => {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [imageSource, setImageSource] = useState("");

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      setImageSource(result.uri);
    }
  };

  const savePresenter = async () => {
    if (name == "" || imageSource == "" || id == "") {
      alert("Please enter all fields!");
      return;
    }
    const compressedImg = await ImageManipulator.manipulateAsync(
      imageSource,
      [],
      {
        compress: 0.5,
      }
    );
    await uploadPresenter({
      name: name,
      image: compressedImg.uri,
      id: id,
    });
    setName("");
    setImageSource("");
    setId("");
  };

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        style={{ flex: 1, width: "100%" }}
        keyboardShouldPersistTaps="always"
      >
        <View style={{ alignItems: "center", justifyContent: "center" }}>
          <Text>Add Presenter Info</Text>
          {imageSource ? (
            <Image source={{ uri: imageSource }} style={styles.image} />
          ) : (
            <></>
          )}
        </View>

        <View style={styles.button}>
          <TouchableOpacity
            onPress={() => {
              pickImage();
            }}
          >
            <Text style={styles.buttonTitle}>Upload Image</Text>
          </TouchableOpacity>
        </View>
        <TextInput
          style={styles.input}
          placeholder="Name"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => setName(text)}
          value={name}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          style={styles.input}
          placeholder="User Id"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => setId(text)}
          value={id}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <View style={styles.button}>
          <TouchableOpacity
            onPress={() => {
              savePresenter();
            }}
          >
            <Text style={styles.buttonTitle}>Submit</Text>
          </TouchableOpacity>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

var { width, height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  imageBox: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  input: {
    height: 48,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
    borderWidth: 1,
  },
  image: {
    alignSelf: "center",
    width: width * 0.9,
    height: height * 0.25,
    // borderWidth: 1
  },
  multiLineInput: {
    height: 150,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
  },
  button: {
    backgroundColor: "#4169e1",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    height: 48,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonTitle: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  footerView: {
    flex: 1,
    alignItems: "center",
    marginTop: 20,
  },
  footerText: {
    fontSize: 16,
    color: "#2e2e2d",
  },
  footerLink: {
    color: "#4169e1",
    fontWeight: "bold",
    fontSize: 16,
  },
});
