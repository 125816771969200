import React, { useState, useEffect } from "react";
import {
  SafeAreaView,
  TextInput,
  ScrollView,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Modal,
  StatusBar,
  Pressable,
} from "react-native";
import { PolaroidImage } from "../../components/PolaroidImage";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { PitchCard } from "../../components/PitchCard";
import { ModalBar } from "../../components/ModalBar";
import { useSelector } from "react-redux";
import { sendValentine, getTimestamp } from "../../../firebase";
import * as ImageManipulator from "expo-image-manipulator";

export const MessageModal = ({
  modalVisible,
  setModalVisible,
  modalInformation,
  setContent,
  navigation,
  // userData,
}) => {
  const [message, setMessage] = useState("");
  const userData = useSelector((state) => state.userData);
  function onCloseTapped() {
    setContent(null);
    setModalVisible(false);
  }

  async function onMessageSend() {
    if (message == "") {
      alert("Please enter a message!");
      return;
    }
    if (userData.name == "") {
      alert("Please provide your name!");
      return;
    }
    if (!validateUserProfile(userData)) {
      alert("Please Create A Profile First!");
      return;
    }
    var compressedImageUri = "";
    var compressedImg;
    if (userData.image_source != "") {
      try {
        compressedImg = await ImageManipulator.manipulateAsync(
          userData.image_source,
          [],
          {
            compress: 0.5,
          }
        );
      } catch (e) {
        console.log(e);
      }
    }

    compressedImg
      ? (compressedImageUri = compressedImg.uri)
      : (compressedImageUri = "");

    const userDataObject = {
      name: userData.name,
      image: compressedImageUri,
      instagram: userData.instagram,
      phone: userData.phone,
      email: userData.email,
      message: message,
      presentee: modalInformation.id,
      sentTimestamp: getTimestamp(),
    };
    sendValentine(userDataObject);
    onCloseTapped();
  }
  const profileMissingView = () => {
    return (
      <View style={{ textAlign: "center" }}>
        {/* <Text style={styles.textLarge}>
          Fill out your profile information, to send a message!
        </Text> */}
        <TouchableOpacity
          onPress={() => {
            onCloseTapped();
            navigation.navigate("Profile");
          }}
        >
          <Text style={styles.textLinkLargeBold}>Want to send a message?</Text>
          <Text style={styles.textLarge}>
            Tap here to fill out your profile!
          </Text>
        </TouchableOpacity>
      </View>
    );
  };
  // console.log(userData.image_source);
  const validateUserProfile = (userData) => {
    return (
      !userData ||
      !(
        userData.name == "" &&
        userData.email == "" &&
        userData.phone == "" &&
        userData.instagram == ""
      )
    );
  };
  var titleMessage = modalInformation
    ? `Send a Message to \n${modalInformation.name}`
    : `Send a Message`;
  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={modalVisible}
      onDismiss={onCloseTapped}
    >
      <SafeAreaView style={{ flex: 1, marginBottom: 10 }}>
        <View
          style={{
            flex: 0.5,
            top: 5,
            marginBottom: 5,
          }}
        >
          <ModalBar
            title={titleMessage}
            iconColor="black"
            iconSize="30"
            onPress={onCloseTapped}
          />
        </View>

        <KeyboardAwareScrollView
          style={{ flex: 7, width: "100%", alignContent: "center" }}
          keyboardShouldPersistTaps="always"
        >
          <View style={{ flexDirection: "column", alignItems: "center" }}>
            {modalInformation ? (
              <PitchCard
                id={modalInformation.id}
                photo={modalInformation.photo}
              />
            ) : (
              <View />
            )}
            {validateUserProfile(userData) ? (
              <PolaroidImage
                photo={userData.image_source}
                name={userData.name}
                phone={userData.phone}
                email={userData.email}
                insta={userData.instagram}
                onImageClicked={() => {}}
              />
            ) : (
              profileMissingView()
            )}
          </View>
          {validateUserProfile(userData) ? (
            <View>
              <TextInput
                style={styles.multiLineInput}
                placeholder="Message"
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => setMessage(text)}
                value={message}
                multiline={true}
                numberOfLines={10}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                maxLength={400}
              />
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  onMessageSend();
                }}
              >
                <Text style={styles.buttonTitle}>Send</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View />
          )}

          <TouchableOpacity
            style={styles.button}
            onPress={() => onCloseTapped()}
          >
            <Text style={styles.buttonTitle}>Close</Text>
          </TouchableOpacity>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center",
    marginTop: StatusBar.currentHeight || 0,
  },
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: "600",
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: "400",
  },
  highlight: {
    fontWeight: "700",
  },
  hstack: {
    flex: 1,
    flexDirection: "row",
  },
  input: {
    height: 48,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
  },
  multiLineInput: {
    height: 150,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
    borderWidth: 1,
  },
  button: {
    backgroundColor: "#4169e1",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    height: 48,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonTitle: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  textLinkLargeBold: {
    // backgroundColor: 'white',
    fontSize: 20,
    margin: 10,
    alignSelf: "center",
    fontWeight: "bold",
  },
  textLarge: {
    // backgroundColor: 'white',
    fontSize: 20,
    margin: 10,
    alignSelf: "center",
  },
  textMedium: {
    // backgroundColor: 'white',
    fontSize: 15,
    margin: 10,
    alignSelf: "center",
  },
  textSmall: {
    // backgroundColor: 'white',
    fontSize: 10,
    alignSelf: "center",
  },
});
