import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer } from "@react-navigation/native";
import { HomeScreen } from "../screens/Home";
import { ProfileScreen } from "../screens/Profile";
import Ionicons from "react-native-vector-icons/Ionicons";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { AdminScreen } from "../screens/Admin";
import { PresenteeScreen } from "../screens/Presentee";
import { StatsScreen } from "../screens/Stats";
import { useSelector, useDispatch } from "react-redux";
import { PresentationsScreen } from "../screens/Presentations";

export default function MainApp() {
  const Stack = createStackNavigator();
  const Tab = createBottomTabNavigator();
  const [authedUser, loading, error] = useAuthState(auth);
  return (
    <NavigationContainer>
      <Tab.Navigator initialRouteName="Home" backBehavior="history">
        <Tab.Screen
          name="Home"
          options={{
            title: "Home",
            headerShown: false,
            tabBarIcon: ({ color }) => (
              <TabBarIcon5 name="house-user" color={color} />
            ),
          }}
          component={HomeScreen}
        />
        <Tab.Screen
          name="Profile"
          options={{
            title: "Profile",
            headerShown: false,
            tabBarIcon: ({ color }) => (
              <TabBarIconIonic name="person-circle-outline" color={color} />
            ),
          }}
          component={ProfileScreen}
        />
        {authedUser && authedUser.uid == "n4QgGWhSHJVWPXreEHZmR7h26uX2" ? (
          <Tab.Screen
            name="Admin"
            options={{
              title: "Admin",
              headerShown: false,
              tabBarIcon: ({ color }) => (
                <TabBarIconIonic name="settings" color={color} />
              ),
            }}
            component={AdminScreen}
          />
        ) : (
          <></>
        )}
        {authedUser && authedUser.uid == "n4QgGWhSHJVWPXreEHZmR7h26uX2" ? (
          <Tab.Screen
            name="Presentations"
            options={{
              title: "Presentations",
              headerShown: false,
              tabBarIcon: ({ color }) => (
                <TabBarIcon name="slideshare" color={color} />
              ),
            }}
            component={PresentationsScreen}
          />
        ) : (
          <></>
        )}
        {authedUser && authedUser.uid == "n4QgGWhSHJVWPXreEHZmR7h26uX2" ? (
          <Tab.Screen
            name="Stats"
            options={{
              title: "Stats",
              headerShown: false,
              tabBarIcon: ({ color }) => (
                <TabBarIcon name="bar-chart" color={color} />
              ),
            }}
            component={StatsScreen}
          />
        ) : (
          <></>
        )}

        {authedUser && authedUser.uid != "n4QgGWhSHJVWPXreEHZmR7h26uX2" ? (
          <Tab.Screen
            name="Messages"
            options={{
              title: "Messages",
              headerShown: false,
              tabBarIcon: ({ color }) => (
                <TabBarIconIonic name="chatbox-outline" color={color} />
              ),
            }}
            component={PresenteeScreen}
          />
        ) : (
          <></>
        )}
      </Tab.Navigator>
    </NavigationContainer>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

function TabBarIcon5(props) {
  return <FontAwesome5 size={30} style={{ marginBottom: -3 }} {...props} />;
}

function TabBarIconIonic(props) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
