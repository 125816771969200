import { Camera, CameraType } from "expo-camera";
import { useState, useRef } from "react";
import {
  ImageBackground,
  SafeAreaView,
  Button,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";
import { Image } from "react-native-web";
import { useDispatch } from "react-redux";
import { setUserPhoto, saveUser } from "../features/userDataSlice";

export const CameraPreviewView = ({
  photo,
  setPreviewVisible,
  setCapturedImage,
  setImage,
  setModalVisible,
}) => {
  const dispatch = useDispatch();

  function onSave() {
    dispatch(setUserPhoto(photo.uri));
    dispatch(saveUser());
    setImage(photo.uri);
    setModalVisible(false);
  }

  function onTrash() {
    setCapturedImage(null);
    setPreviewVisible(false);
  }

  return (
    <SafeAreaView
      style={{
        backgroundColor: "transparent",
        flex: 1,
        width: "100%",
        height: "100%",
      }}
    >
      <ImageBackground
        source={{ uri: photo && photo.uri }}
        style={{
          flex: 1,
        }}
      />
      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={styles.button}
          onPress={async () => {
            onTrash();
          }}
        >
          {/* <Text style={styles.text}>Take Picture</Text> */}
          <TabBarIconIonic name="ios-trash-bin" />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            onSave();
          }}
        >
          {/* <Text style={styles.text}>Close</Text> */}
          <TabBarIconIonic name="checkmark-circle" />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

function TabBarIconIonic(props) {
  return (
    <Ionicons
      size={55}
      style={{ marginBottom: -3, color: "white" }}
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    // flexDirection: 'column',
    backgroundColor: "black",
  },
  camera: {
    flex: 3,
  },
  buttonContainer: {
    // flex: 1,
    backgroundColor: "black",
    flexDirection: "row",
    // backgroundColor: 'transparent',
    // margin: 64,
    // marginTop: '100%',
  },
  button: {
    flex: 1,
    alignSelf: "flex-end",
    alignItems: "center",
  },
  text: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
  },
});
