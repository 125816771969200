import {
  StatusBar,
  Image,
  SafeAreaView,
  Button,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Shadow } from "react-native-shadow-2";
import { IconButton } from "./IconButton/IconButton";

export const ModalBar = ({ title, onPress, iconColor }) => {
  return (
    <View style={styles.container}>
      <View style={styles.buttonView}>
        <IconButton
          iconName="chevron-back"
          onPress={onPress}
          iconColor={iconColor}
        />
      </View>
      <View style={styles.titleView}>
        <Text style={styles.text}>{title}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginTop: StatusBar.currentHeight || 10,
    // marginBottom: 20,
  },
  buttonView: {
    flex: 0.5,
    marginLeft: 15,
    alignItems: "flex-start",
  },
  titleView: {
    flex: 6,
    alignItems: "center",
    textAlign: "center",
  },
  text: {
    fontSize: 24,
    fontWeight: "bold",
    color: "black",
  },
});
