import { configureStore } from "@reduxjs/toolkit";
import userDataSlice from "../features/userDataSlice";
import valentineDataSlice from "../features/valentineDataSlice";

export const store = configureStore({
  reducer: {
    userData: userDataSlice,
    valentinesData: valentineDataSlice,
  },
});
