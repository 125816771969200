import React, { useState, useEffect, useRef } from "react";
import {
  SafeAreaView,
  TextInput,
  ScrollView,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Modal,
  StatusBar,
  Pressable,
  Animated,
} from "react-native";
import { PolaroidImage } from "../../components/PolaroidImage";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ModalBar } from "../../components/ModalBar";
import { MessageBubble } from "../../components/MessageBubble/MessageBubble";
import { ContactInfo } from "../../components/ContactInfo";
import ReactCardFlip from "react-card-flip";

export const ValentineModal = ({
  modalVisible,
  setModalVisible,
  setContent,
  valentineInfo,
}) => {
  const [message, setMessage] = useState("");
  const [polaroidIsFlipped, flipPolaroid] = useState(false);

  function onCloseTapped() {
    setContent(null);
    setModalVisible(false);
  }

  var titleMessage = `Message from ${valentineInfo.name}`;
  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={modalVisible}
      onDismiss={onCloseTapped}
    >
      <SafeAreaView style={{ flex: 1 }}>
        <View
          style={{
            flex: 0.5,
            top: 5,
            marginBottom: 5,
          }}
        >
          <ModalBar
            title={titleMessage}
            iconColor="black"
            iconSize="30"
            onPress={onCloseTapped}
          />
        </View>

        <KeyboardAwareScrollView
          style={{ flex: 7, width: "100%", alignContent: "center" }}
          keyboardShouldPersistTaps="always"
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <ReactCardFlip
              isFlipped={polaroidIsFlipped}
              flipDirection="vertical"
            >
              <View>
                <PolaroidImage
                  photo={valentineInfo.image}
                  name={valentineInfo.name}
                  phone={valentineInfo.phone}
                  email={valentineInfo.email}
                  insta={valentineInfo.instagram}
                  onImageClicked={() => {
                    flipPolaroid(true);
                  }}
                />
              </View>
              <View>
                <PolaroidImage
                  photo={valentineInfo.image}
                  name={valentineInfo.name}
                  phone={valentineInfo.phone}
                  email={valentineInfo.email}
                  insta={valentineInfo.instagram}
                  onImageClicked={() => {
                    flipPolaroid(false);
                  }}
                  back={true}
                />
                {/* <ContactInfo valentineInfo={valentineInfo} /> */}
              </View>
            </ReactCardFlip>
            {/* <Animated.View style={styles.cardBack}>
                <ContactInfo valentineInfo={valentineInfo} />
              </Animated.View> */}
          </View>
          {/* View for the rest of the page */}
          <View>
            <View>
              <Text style={styles.textLargeBold}>
                Message from {valentineInfo.name}:
              </Text>
              <MessageBubble message={valentineInfo.message} />
              {/* <Text style={styles.textLarge}>{valentineInfo.message}</Text> */}
            </View>
            <View style={styles.container}>
              <Text style={styles.textLargeBold}>
                Want to Respond? Reach out to them using their info above!
              </Text>
            </View>
            <TouchableOpacity
              style={styles.button}
              onPress={() => onCloseTapped()}
            >
              <Text style={styles.buttonTitle}>Close</Text>
            </TouchableOpacity>
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center",
    marginTop: StatusBar.currentHeight || 0,
  },
  cardFront: {
    // position: "absolute",
  },
  cardBack: {
    backfaceVisibility: "hidden",
  },
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: "600",
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: "400",
  },
  highlight: {
    fontWeight: "700",
  },
  hstack: {
    flex: 1,
    flexDirection: "row",
  },
  input: {
    height: 48,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
  },
  multiLineInput: {
    height: 150,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
    borderWidth: 1,
  },
  button: {
    backgroundColor: "#4169e1",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    height: 48,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonTitle: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  textLarge: {
    // backgroundColor: 'white',
    fontSize: 20,
    margin: 25,
    alignSelf: "center",
  },
  textLargeBold: {
    // backgroundColor: 'white',
    fontSize: 20,
    margin: 25,
    alignSelf: "center",
    fontWeight: "bold",
  },
  textMedium: {
    // backgroundColor: 'white',
    fontSize: 15,
    margin: 10,
    alignSelf: "center",
  },
  textSmall: {
    // backgroundColor: 'white',
    fontSize: 10,
    alignSelf: "center",
  },
});
