import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  setDoc,
  getDoc,
  doc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import uuid from "react-native-uuid";

const firebaseConfig = {
  apiKey: "AIzaSyAgU0ge4q1VMPHHyRnpGHGRp74IgqZOUO4",
  authDomain: "project-valentine-eef9b.firebaseapp.com",
  projectId: "project-valentine-eef9b",
  storageBucket: "project-valentine-eef9b.appspot.com",
  messagingSenderId: "75746401734",
  appId: "1:75746401734:web:4875c754295dbb741366c7",
  measurementId: "G-FZCL2CF88G",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const getTimestamp = () => {
  return Timestamp.fromDate(new Date());
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const fetchPresentees = async (setPresenteesCallback) => {
  const presentees = [];
  try {
    const q = query(collection(db, "presentees"), where("visible", "==", true));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      presentees.push(doc.data());
    });
    // console.log(preentees);
    setPresenteesCallback(presentees);
  } catch (e) {
    alert("Failed to fetch new presentees, refresh to try again!");
    console.log(e);
  }
};

const fetchValentines = async (id, setValentinesCallback) => {
  const q = query(collection(db, "valentines"), where("to", "==", id));
  const valentines = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    valentines.push(doc.data());
  });
  // console.log(valentines);
  setValentinesCallback(valentines);
};

const fetchRemoteValentines = async (id) => {
  try {
    const q = query(collection(db, "valentines"), where("to", "==", id));
    const valentines = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      valentines.push(doc.data());
    });
    // console.log(valentines);
    return valentines;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const uploadPresenter = async (userData) => {
  try {
    const id = uuid.v4();
    await setDoc(doc(db, "presentees", id), {
      name: userData.name,
      image: userData.image,
      docName: id,
      id: userData.id,
      visible: false,
    });
    alert("Upload Success");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const fetchAdminPresentees = async (setPresenteesCallback) => {
  const q = query(collection(db, "presentees"));
  const presentees = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    presentees.push(doc.data());
  });
  // setPresenteesCallback(presentees);
  return presentees;
};

const changePresenteeVisibility = async (presenteeId, visibility) => {
  try {
    const presenteeDoc = doc(db, "presentees", presenteeId);
    await updateDoc(presenteeDoc, {
      visible: visibility,
    });
  } catch (e) {
    alert(e);
  }
};

const fetchPresenteesAndStats = async (callback) => {
  try {
    const presentees = await fetchAdminPresentees();
    var counter = 0;
    presentees.forEach(async (item) => {
      const q = query(collection(db, "valentines"), where("to", "==", item.id));
      const querySnapshot = await getDocs(q);
      // console.log(`${item.name}: ${querySnapshot.size} ${counter}`);
      item.messages = querySnapshot.size;
      if (counter == presentees.length - 1) callback(presentees);
      counter++;
    });
    // console.log("Presentees ", JSON.stringify(presentees[8]));
  } catch (e) {
    console.log(e);
    return;
  }
};

/*
 *   userdata is of the scheme:
 * {
 * name: "Name of person"
 * instagram: "instagram of person"
 * phone: "phone of person"
 * message: "message from person",
 * email: "email from person",
 * image: "image of person"
 * presentee: "id of presenter"
 * }
 *
 *
 */
const sendValentine = async (userData) => {
  try {
    const id = uuid.v4();
    await setDoc(doc(db, "valentines", id), {
      name: userData.name,
      image: userData.image,
      instagram: userData.instagram,
      phone: userData.phone,
      email: userData.email,
      message: userData.message,
      id: id,
      to: userData.presentee,
      sentTimestamp: userData.sentTimestamp,
    });
    alert("Message Sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  uploadPresenter,
  logout,
  fetchPresentees,
  sendValentine,
  fetchValentines,
  fetchRemoteValentines,
  getTimestamp,
  fetchAdminPresentees,
  changePresenteeVisibility,
  fetchPresenteesAndStats,
};
