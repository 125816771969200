import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AsyncStorage from "@react-native-async-storage/async-storage";

const initialState = {
  name: "",
  instagram: "",
  phone: "",
  email: "",
  image_source: "",
};

export const saveUser = createAsyncThunk(
  "userData/saveUser",
  async (userData, thunkAPI) => {
    try {
      const userDataState = thunkAPI.getState();
      try {
        await AsyncStorage.setItem(
          "@user_details",
          JSON.stringify(userDataState.userData)
        );
      } catch (e) {
        console.log(e);
        // alert("Failed to store user data, try again");
        return thunkAPI.rejectWithValue(e);
      }
      return;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const clearUser = createAsyncThunk(
  "userData/clearUser",
  async (userData, thunkAPI) => {
    try {
      try {
        await AsyncStorage.removeItem("@user_details");
      } catch (e) {
        console.log(e);
        // alert("Failed to store user data, try again");
        return thunkAPI.rejectWithValue(e);
      }
      return initialState;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadUser = createAsyncThunk(
  "userData/loadUser",
  async (userData, thunkAPI) => {
    try {
      try {
        const userString = await AsyncStorage.getItem("@user_details");
        if (userString != null) {
          const userObject = JSON.parse(userString);
          // console.log("FOUND DATA");
          return userObject;
        }
        return initialState;
      } catch (e) {
        console.log(e);
        alert("Failed to fetch user data, try again");
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const userProfileSlice = createSlice({
  name: "userData",
  initialState: initialState,
  reducers: {
    setUserProfile(state, action) {
      state = action.payload;
      return state;
    },
    setUserName(state, action) {
      return { ...state, name: action.payload };
    },
    setUserInstagram(state, action) {
      return { ...state, instagram: action.payload };
    },
    setUserEmail(state, action) {
      return { ...state, email: action.payload };
    },
    setUserPhone(state, action) {
      return { ...state, phone: action.payload };
    },
    setUserPhoto(state, action) {
      return { ...state, image_source: action.payload };
    },
  },
  extraReducers: {
    [saveUser.pending]: (state) => {
      return state;
    },
    [saveUser.fulfilled]: (state) => {
      // state = payload;
      return state;
    },
    [saveUser.rejected]: (state) => {
      state = initialState;
      return state;
    },
    [loadUser.pending]: (state) => {
      return state;
    },
    [loadUser.fulfilled]: (state, { payload }) => {
      // state = payload;
      return payload;
    },
    [loadUser.rejected]: (state) => {
      state = initialState;
      return state;
    },
    [clearUser.pending]: (state) => {
      return state;
    },
    [clearUser.fulfilled]: (state, { payload }) => {
      // state = payload;
      return payload;
    },
    [clearUser.rejected]: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  setUserProfile,
  setUserName,
  setUserInstagram,
  setUserEmail,
  setUserPhone,
  setUserPhoto,
} = userProfileSlice.actions;
export default userProfileSlice.reducer;
