import { Dimensions, Image, SafeAreaView, Button, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';

export const IconButton = ({label, onPress, iconName, iconSize, iconColor}) => {
    return(
        <View>
            <TouchableOpacity
            style={styles.button}
            onPress={onPress}>
                <TabBarIconIonic name={iconName} size={iconSize?iconSize:24} color={iconColor?iconColor:'black'}/>
            </TouchableOpacity>
            
        </View>
    )
};


function TabBarIconIonic(props) {
    return <Ionicons style={{ marginBottom: -3}} {...props}/>;
}

const styles = StyleSheet.create({
    button: {
      flex: 1,
      alignItems: 'center',
    },
    text: {
      fontSize: 24,
      fontWeight: 'bold',
      color: 'white',
    },
  });
