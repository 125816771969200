import {
  Dimensions,
  Image,
  Linking,
  Button,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Shadow } from "react-native-shadow-2";
import * as Font from "expo-font";
import Ionicons from "react-native-vector-icons/Ionicons";
import FontAwesome from "react-native-vector-icons/FontAwesome";

export const PolaroidImage = ({
  photo,
  name,
  email,
  phone,
  insta,
  message,
  doubleside,
  onImageClicked,
  back,
}) => {
  const getFonts = () => {
    Font.loadAsync({
      marker: require("./assets/fonts/PermanentMarker-Regular.ttf"),
    });
  };
  getFonts();

  if (back) {
    return (
      <View style={styles.container}>
        <Shadow style={{ backgroundColor: "white" }}>
          <View style={styles.polaroid}>
            <View style={styles.image}>
              <View style={styles.backContainer}>
                {name ? (
                  <View style={styles.row}>
                    <View style={styles.iconView}>
                      <Text style={{ fontWeight: "bold" }}>Name:</Text>
                    </View>
                    <View style={styles.textView}>
                      <Text> {name}</Text>
                    </View>
                  </View>
                ) : (
                  <View />
                )}
                {insta ? (
                  <View style={styles.row}>
                    <View style={styles.iconView}>
                      <Icon name="instagram" />
                    </View>

                    <View style={styles.textView}>
                      <TouchableOpacity
                        onPress={() =>
                          Linking.openURL(`https://www.instagram.com/${insta}`)
                        }
                      >
                        <Text styles={styles.backLinkText}>
                          {!insta ? `` : `@${insta}`}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : (
                  <View />
                )}
                {phone ? (
                  <View style={styles.row}>
                    <View style={styles.iconView}>
                      <Icon name="phone" />
                    </View>
                    <View style={styles.textView}>
                      <Text styles={styles.contactText}> {phone}</Text>
                    </View>
                  </View>
                ) : (
                  <View />
                )}
                {email ? (
                  <View style={styles.row}>
                    <View style={styles.iconView}>
                      <Icon name="envelope-o" />
                    </View>
                    <View style={styles.textView}>
                      <Text styles={styles.contactText}> {email}</Text>
                    </View>
                  </View>
                ) : (
                  <View />
                )}
              </View>
            </View>
            <View style={styles.backContactCaptionBox}>
              <TouchableOpacity onPress={onImageClicked}>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <IconIonic name="return-up-back" />
                  <Text style={{ alignSelf: "center" }}>Back</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </Shadow>
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <Shadow style={{ backgroundColor: "white" }}>
        <View style={styles.polaroid}>
          {onImageClicked ? (
            <TouchableOpacity onPress={onImageClicked}>
              {!photo ? (
                <Image
                  source={require("./blank-profile.png")}
                  style={styles.image}
                />
              ) : (
                <Image source={{ uri: photo }} style={styles.image} />
              )}
            </TouchableOpacity>
          ) : !photo ? (
            <Image
              source={require("./blank-profile.png")}
              style={styles.image}
            />
          ) : (
            <Image source={{ uri: photo }} style={styles.image} />
          )}
          <View style={styles.nameBox}>
            <Text style={styles.text}>{name}</Text>
            {/* <Text style={styles.text}>     </Text> */}
            <TouchableOpacity
              onPress={() =>
                Linking.openURL(`https://www.instagram.com/${insta}`)
              }
            >
              <Text style={styles.linkText}>{!insta ? `` : `@${insta}`}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.contactCaptionBox}>
            <Text style={styles.phoneText}>{phone}</Text>
            {email && email.length < 12 ? (
              <Text style={styles.text}>{email}</Text>
            ) : (
              <Text style={styles.textSmall}>{email}</Text>
            )}
          </View>
        </View>
      </Shadow>
    </View>
  );
};

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function Icon(props) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

function IconIonic(props) {
  return <Ionicons size={50} style={{ marginBottom: -3 }} {...props} />;
}

var { width } = Dimensions.get("window");
const styles = StyleSheet.create({
  container: {
    // flex: 1,

    // padding: 1,
    // margin: 15,
    backgroundColor: "transparent",
    alignItems: "center",
  },
  backContainer: {
    flex: 1,
    // padding: 1,
    // margin: 15,
    backgroundColor: "transparent",
    // alignItems: "center",
  },
  // text: { fontSize: 16, color: "#000", marginBottom: -3 },
  textView: {
    justifyContent: "center",
    marginLeft: 10,
    flex: 5,
  },
  iconView: {
    flex: 1.25,
  },
  row: { flexDirection: "row", margin: 10 },
  IconText: {
    // flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  polaroid: {
    backgroundColor: "white",
    borderColor: "white",
    margin: 10,
    marginBottom: 40,
  },
  nameBox: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  backContactCaptionBox: {
    marginTop: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    alignSelf: "center",
    width: width * 0.75,
    height: width * 0.45,
    borderWidth: 1,
  },
  text: {
    // backgroundColor: 'white',
    fontSize: 20,
    fontFamily: "marker",
    alignSelf: "center",
  },
  linkText: {
    // backgroundColor: 'white',
    fontSize: 20,
    textDecorationLine: "underline",
    fontFamily: "marker",
    alignSelf: "center",
  },
  phoneText: {
    letterSpacing: 2,
    fontSize: 15,
    fontFamily: "marker",
    alignSelf: "center",
  },
  textMedium: {
    // backgroundColor: 'white',
    fontSize: 15,
    fontFamily: "marker",
    alignSelf: "center",
  },
  textSmall: {
    // backgroundColor: 'white',
    fontSize: 14,
    fontFamily: "marker",
    alignSelf: "center",
  },
  backText: {
    // backgroundColor: 'white',
    fontSize: 20,
    alignSelf: "center",
  },
  backLinkText: {
    // backgroundColor: 'white',
    fontSize: 20,
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    alignSelf: "center",
  },
  backPhoneText: {
    letterSpacing: 2,
    fontSize: 15,
    alignSelf: "center",
  },
  backTextMedium: {
    // backgroundColor: 'white',
    fontSize: 15,
    alignSelf: "center",
  },
  backTextSmall: {
    // backgroundColor: 'white',
    fontSize: 14,
    alignSelf: "center",
  },
});
