import React, { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  SafeAreaView,
  ScrollView,
  FlatList,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  StatusBar,
  RefreshControl,
  Dimensions,
} from "react-native";

import { PitchCard } from "../components/PitchCard";
import { MessageModal } from "../modals/MessageModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserProfile, loadUser } from "../features/userDataSlice";
import { LoginModal } from "../modals/LoginModal/LoginModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout, fetchPresentees } from "../../firebase";
import { loadValentines } from "../features/valentineDataSlice";

export const HomeScreen = ({ navigation }) => {
  // const [userData, setUserData] = useState(null);
  const userData = useSelector((state) => state.userData);
  const [refreshing, setRefreshing] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState(null);
  const [showAdmingLoginModal, setShowAdmingLoginModal] = useState(false);
  const [presentees, setPresentees] = useState([]);
  const [authedUser, loading, error] = useAuthState(auth);
  const dispatch = useDispatch();
  function onPitchClicked(photo, name, id, docName = null) {
    setMessageModalContent({
      photo: photo,
      name: name,
      id: id,
    });
    setShowMessageModal(true);
  }

  useEffect(() => {
    // console.log("[Home]: Loading data....");
    fetchPresentees(setPresentees);
    if (
      userData.name == "" &&
      userData.phone == "" &&
      userData.email == "" &&
      userData.image_source == "" &&
      userData.instagram == ""
    ) {
      dispatch(loadUser());
    }
  }, []);

  function renderItem({ item }) {
    return (
      <View>
        <PitchCard
          name={item.name}
          id={item.id}
          photo={item.image}
          onPitchClicked={onPitchClicked}
        />
      </View>
    );
  }

  // console.log("User Data Updated");
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        contentInsetAdjustmentBehavior="automatic"
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        <View>
          {authedUser ? (
            <Text style={styles.sectionAdminTitle}>
              Welcome To Pitch A Friend
            </Text>
          ) : (
            <Text style={styles.sectionTitle}>Welcome To Pitch A Friend</Text>
          )}
        </View>
        {showMessageModal ? (
          <MessageModal
            setModalVisible={setShowMessageModal}
            modalVisible={showMessageModal}
            modalInformation={messageModalContent}
            setContent={setMessageModalContent}
            navigation={navigation}
          />
        ) : (
          <View />
        )}
        {showAdmingLoginModal ? (
          <LoginModal
            setModalVisible={setShowAdmingLoginModal}
            modalVisible={showAdmingLoginModal}
          />
        ) : (
          <View />
        )}
        {presentees.length == [] ? (
          <View style={{ height: height * 0.75 }}>
            <Text>Presentees will load shortly after each presentation.</Text>
          </View>
        ) : (
          <View />
        )}
        <FlatList
          data={presentees}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                fetchPresentees(setPresentees);
                setRefreshing(false);
              }}
            />
          }
        />

        {!authedUser ? (
          <View style={styles.footer}>
            <TouchableOpacity
              onPress={() => {
                setShowAdmingLoginModal(true);
              }}
            >
              <Text>User Login</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <View style={styles.footer}>
            <TouchableOpacity
              onPress={() => {
                logout();
              }}
            >
              <Text>Sign Out</Text>
            </TouchableOpacity>
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

var { height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    marginTop: StatusBar.currentHeight || 0,
  },
  footer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 5,
    marginBottom: 10,
  },
  pitchContainer: {
    flex: 1,
    justifyContent: "center",
  },
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    alignSelf: "center",
    fontWeight: "600",
    margin: 25,
  },
  sectionAdminTitle: {
    fontSize: 24,
    alignSelf: "center",
    fontWeight: "600",
    margin: 25,
    color: "red",
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: "400",
  },
  highlight: {
    fontWeight: "700",
  },
  hstack: {
    flex: 1,
    flexDirection: "row",
  },
});
