import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import MainApp from "./src/navigation/MainApp";
import { Provider } from "react-redux";
import { store } from "./src/store";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export default function App() {
  return (
    // <View style={styles.container}>
    //   <Text>Open up App.js to start working on your app!</Text>
    //   <StatusBar style="auto" />
    // </View>
    <Provider store={store}>
      <MainApp />
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
