import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  auth,
  logout,
  fetchPresentees,
  fetchRemoteValentines,
} from "../../firebase";

const initialState = [];

// export const saveUser = createAsyncThunk(
//   "userData/saveUser",
//   async (userData, thunkAPI) => {
//     try {
//       const userDataState = thunkAPI.getState();
//       try {
//         await AsyncStorage.setItem(
//           "@user_details",
//           JSON.stringify(userDataState.userData)
//         );
//       } catch (e) {
//         console.log(e);
//         // alert("Failed to store user data, try again");
//         return thunkAPI.rejectWithValue(e);
//       }
//       return;
//     } catch (error) {
//       console.log(error);
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const loadValentines = createAsyncThunk(
  "valentineData/loadValentines",
  async (userData, thunkAPI) => {
    try {
      try {
        const valentines = await fetchRemoteValentines(userData);
        // console.log(`Found ${valentines.length} valentines!`);
        return valentines;
      } catch (e) {
        console.log(e);
        // alert("Failed to fetch user data, try again");
        return thunkAPI.rejectWithValue(error);
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const valentineDataSlice = createSlice({
  name: "valentineData",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [loadValentines.pending]: (state) => {
      return state;
    },
    [loadValentines.fulfilled]: (state, { payload }) => {
      // state = payload;
      return payload;
    },
    [loadValentines.rejected]: (state) => {
      return state;
    },
  },
});

export const {} = valentineDataSlice.actions;
export default valentineDataSlice.reducer;
