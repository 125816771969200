import {
  Dimensions,
  Image,
  Linking,
  Button,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Shadow } from "react-native-shadow-2";
import * as Font from "expo-font";
import Ionicons from "react-native-vector-icons/Ionicons";

export const PitchCard = ({
  photo,
  name,
  id,
  onPitchClicked,
  toggleSwitchAction,
}) => {
  return onPitchClicked ? (
    <TouchableOpacity
      style={styles.button}
      onPress={() => (onPitchClicked ? onPitchClicked(photo, name, id) : {})}
    >
      <View style={styles.container}>
        <Shadow style={{ backgroundColor: "white" }}>
          <Image source={{ uri: photo }} style={styles.image} />
          <Text style={styles.textLarge}>{name}</Text>
        </Shadow>
      </View>
    </TouchableOpacity>
  ) : (
    // <TouchableOpacity style={styles.button}>
    <View style={styles.container}>
      <Shadow style={{ backgroundColor: "white" }}>
        <Image source={{ uri: photo }} style={styles.image} />
        <Text style={styles.textLarge}>{name}</Text>
      </Shadow>
    </View>
    // </TouchableOpacity>
  );
};

var { width, height } = Dimensions.get("window");
const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 1,
    // margin: 15,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
    margin: 10,
    width: width * 0.9,
    height: height * 0.25,
    borderRadius: 10,
  },
  image: {
    alignSelf: "center",
    width: width * 0.9,
    height: height * 0.25,
    // borderWidth: 1
  },
  text: {
    // backgroundColor: 'white',
    fontSize: 20,
    fontFamily: "marker",
    alignSelf: "center",
  },
  linkText: {
    // backgroundColor: 'white',
    fontSize: 20,
    textDecorationLine: "underline",
    fontFamily: "marker",
    alignSelf: "center",
  },
  textLarge: {
    fontSize: 40,
    alignSelf: "center",
  },
  textMedium: {
    // backgroundColor: 'white',
    fontSize: 15,
    fontFamily: "marker",
    alignSelf: "center",
  },
  textSmall: {
    // backgroundColor: 'white',
    fontSize: 10,
    fontFamily: "marker",
    alignSelf: "center",
  },
});
