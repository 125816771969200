import React, { useState, useEffect } from "react";
import {
  Dimensions,
  Button,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Image,
  StyleSheet,
  FlatList,
  Alert,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Switch } from "react-native-web";
import {
  fetchAdminPresentees,
  changePresenteeVisibility,
} from "../../../firebase";
import { PitchCard } from "../PitchCard";

export const AdminPresentationListing = ({ navigation }) => {
  //   const [name, setName] = useState("");
  //   const [id, setId] = useState("");
  //   const [imageSource, setImageSource] = useState("");
  const [presentees, setPresenteesCallback] = useState([]);

  useEffect(() => {
    // console.log("[Admin]: Loading data....");
    // fetchAdminPresentees(setPresenteesCallback);
    fetchPresentees();
  }, []);

  async function fetchPresentees() {
    const presentees = await fetchAdminPresentees(setPresenteesCallback);
    setPresenteesCallback(presentees);
  }

  const onPitchClicked = async (item) => {
    alert(
      `Changing Visibility for ${item.name}: ${
        item.visible
      } -> ${!item.visible}`
    );
    await changePresenteeVisibility(item.docName, !item.visible);
    // await fetchAdminPresentees(setPresenteesCallback);
    fetchPresentees();
  };

  function renderItem({ item }) {
    const visibleText = item.visible ? "Visible" : "Hidden";

    return (
      <TouchableOpacity
        onPress={() => {
          //   console.log("PRESSED");
          onPitchClicked(item);
        }}
      >
        <View>
          <PitchCard
            name={`${item.name}: ${visibleText}`}
            id={item.id}
            photo={item.image}
          />
        </View>
      </TouchableOpacity>
    );
  }

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        style={{ flex: 1, width: "100%" }}
        keyboardShouldPersistTaps="always"
      >
        <View>
          <FlatList
            data={presentees}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
          />
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

var { width, height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  imageBox: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  input: {
    height: 48,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
    borderWidth: 1,
  },
  image: {
    alignSelf: "center",
    width: width * 0.9,
    height: height * 0.25,
    // borderWidth: 1
  },
  multiLineInput: {
    height: 150,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
  },
  button: {
    backgroundColor: "#4169e1",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    height: 48,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonTitle: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  footerView: {
    flex: 1,
    alignItems: "center",
    marginTop: 20,
  },
  footerText: {
    fontSize: 16,
    color: "#2e2e2d",
  },
  footerLink: {
    color: "#4169e1",
    fontWeight: "bold",
    fontSize: 16,
  },
});
