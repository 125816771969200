import React, { useState, useEffect } from "react";
import {
  Dimensions,
  Button,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  StyleSheet,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { CameraModal } from "../modals/CameraModal";
import { PolaroidImage } from "./PolaroidImage/";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setUserProfile,
  setUserName,
  setUserInstagram,
  setUserEmail,
  setUserPhone,
  setUserPhoto,
  saveUser,
  loadUser,
  clearUser,
} from "../features/userDataSlice";

export const InputForm = ({ navigation }) => {
  const [cameraModalVisible, setCameraModalVisible] = useState(false);
  const userData = useSelector((state) => state.userData);
  const [name, setName] = useState(userData.name);
  const [phone, setPhone] = useState(userData.phone);
  const [email, setEmail] = useState(userData.email);
  const [instagram, setInstagram] = useState(userData.instagram);
  const [imageSource, setImageSource] = useState(userData.image_source);
  const [message, setMessage] = useState("");
  const [userDataLoaded, setUserDataLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("[Profile]: Loading data....");
    if (
      userData.name == "" &&
      userData.phone == "" &&
      userData.email == "" &&
      userData.image_source == "" &&
      userData.instagram == ""
    ) {
      dispatch(loadUser());
    }
  }, []);

  function onPhoneChange(text) {
    var cleaned = ("" + text).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "",
        number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );

      setPhone(number);
      dispatch(setUserPhone(number));
      dispatch(saveUser());
      return;
    }

    setPhone(text);
    dispatch(setUserPhone(text));
    dispatch(saveUser());
  }

  function onEmailChange(text) {
    dispatch(setUserEmail(text));
    dispatch(saveUser());
    setEmail(text);
  }

  function onInstagramChange(text) {
    dispatch(setUserInstagram(text));
    dispatch(saveUser());
    setInstagram(text);
  }

  function onNameChange(text) {
    dispatch(setUserName(text));
    dispatch(saveUser());
    setName(text);
  }

  const onRegisterPress = async () => {
    //create profile photo
    try {
      dispatch(saveUser());
      alert("Saved! Now go send some messages!");
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const onClearPress = async () => {
    //create profile photo
    try {
      dispatch(clearUser());
      alert("Your profile has been cleared!");
      // alert("Saved! Now go send some messages!");
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const onImageClicked = async () => {
    setCameraModalVisible(true);
  };
  var { width } = Dimensions.get("window");

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        style={{ flex: 1, width: "100%" }}
        keyboardShouldPersistTaps="always"
      >
        <PolaroidImage
          photo={userData.image_source}
          name={userData.name}
          phone={userData.phone}
          email={userData.email}
          insta={userData.instagram}
          onImageClicked={onImageClicked}
        />

        {/* <TouchableOpacity
                style={styles.button}
                onPress={() => setCameraModalVisible(true)}>
                <Text style={styles.buttonTitle}>Add Photo</Text>
            </TouchableOpacity> */}
        {cameraModalVisible ? (
          <CameraModal
            modalVisible={cameraModalVisible}
            setModalVisible={setCameraModalVisible}
            setImage={setImageSource}
          />
        ) : (
          <View />
        )}
        <TextInput
          style={styles.input}
          placeholder="Name"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => onNameChange(text)}
          value={userData.name}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          style={styles.input}
          placeholder="Instagram"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => onInstagramChange(text)}
          value={userData.instagram}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          keyboardType={"phone-pad"}
          style={styles.input}
          placeholder="Phone Number"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => onPhoneChange(text)}
          value={userData.phone}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          maxLength={14}
          textContentType="telephoneNumber"
          dataDetectorTypes="phoneNumber"
        />
        <TextInput
          keyboardType={"email-address"}
          style={styles.input}
          placeholder="Email"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => onEmailChange(text)}
          value={userData.email}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        {/* <TextInput
                style={styles.multiLineInput}
                placeholder='Bio'
                placeholderTextColor="#aaaaaa"
                onChangeText={(text) => setBio(text)}
                value={bio}
                multiline={true}
                numberOfLines={10}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
            /> */}

        <TouchableOpacity
          style={styles.button}
          onPress={() => onRegisterPress()}
        >
          <Text style={styles.buttonTitle}>Save</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={() => onClearPress()}>
          <Text style={styles.buttonTitle}>Clear Profile</Text>
        </TouchableOpacity>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  imageBox: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  input: {
    height: 48,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
    borderWidth: 1,
  },
  multiLineInput: {
    height: 150,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
  },
  button: {
    backgroundColor: "#4169e1",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    height: 48,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonTitle: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  footerView: {
    flex: 1,
    alignItems: "center",
    marginTop: 20,
  },
  footerText: {
    fontSize: 16,
    color: "#2e2e2d",
  },
  footerLink: {
    color: "#4169e1",
    fontWeight: "bold",
    fontSize: 16,
  },
});
