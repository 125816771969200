import { Camera, CameraType } from "expo-camera";
import { useState, useRef } from "react";
import {
  Alert,
  SafeAreaView,
  Button,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";
import { CameraPreviewView } from "./CameraPreviewView";
import { manipulateAsync, FlipType, SaveFormat } from "expo-image-manipulator";

export const CameraView = ({ setModalVisible, setImage }) => {
  const [type, setType] = useState(CameraType.back);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [cameraReady, setCameraReady] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const cameraRef = useRef();

  if (!permission) {
    // Camera permissions are still loading
    return (
      <View style={styles.permissionContainer}>
        <Text style={{ textAlign: "center", color: "black", margin: 55 }}>
          We need your permission to show the camera.
        </Text>
        <View style={{ width: "50%", margin: 25 }}>
          <Button onPress={requestPermission} title="Grant permission" />
        </View>
        <View style={{ width: "50%", margin: 25 }}>
          <Button
            onPress={() => {
              setModalVisible(false);
            }}
            title="Close"
          />
        </View>
      </View>
    );
  }

  checkIfCameraAvailable();
  if (!permission.granted) {
    // Camera permissions are not granted yet
    return (
      <View style={styles.permissionContainer}>
        <Text style={{ textAlign: "center", color: "black", margin: 55 }}>
          We need your permission to show the camera.
        </Text>
        <View style={{ width: "50%", margin: 25 }}>
          <Button onPress={requestPermission} title="Grant permission" />
        </View>
        <View style={{ width: "50%", margin: 25 }}>
          <Button
            onPress={() => {
              setModalVisible(false);
            }}
            title="Close"
          />
        </View>
      </View>
    );
  }

  async function checkIfCameraAvailable() {
    if (!(await Camera.isAvailableAsync())) {
      return (
        <View style={styles.permissionContainer}>
          <Text style={{ textAlign: "center", color: "black", margin: 55 }}>
            This device does not have an available camera.
          </Text>
          <Button
            onPress={() => {
              setModalVisible(false);
            }}
            title="Close"
          />
        </View>
      );
    }
  }

  function setCameraReadyCallback() {
    setCameraReady(true);
  }
  function toggleCameraType() {
    setType((current) =>
      current === CameraType.back ? CameraType.front : CameraType.back
    );
  }

  function onCameraMountFailCallback() {
    return (
      <View style={styles.permissionContainer}>
        <Text style={{ textAlign: "center", color: "black", margin: 55 }}>
          This device does not have an available camera.
        </Text>
        <Button
          onPress={() => {
            setModalVisible(false);
          }}
          title="Close"
        />
      </View>
    );
  }

  async function flipImage(image) {
    const manipResult = await manipulateAsync(
      image.uri,
      [{ flip: FlipType.Horizontal }],
      { compress: 1, format: SaveFormat.PNG }
    );
    return manipResult;
  }

  async function __takePicture() {
    // console.log("Taking a picture");
    if (!cameraRef.current) return;
    if (!cameraReady) return;
    // console.log("Picture is about to be taken");
    var photo = await cameraRef.current.takePictureAsync();
    // console.log(photo);
    if (type == CameraType.front) {
      photo = await flipImage(photo);
    }
    setPreviewVisible(true);
    setCapturedImage(photo);
  }
  // console.log(type);

  if (previewVisible && capturedImage) {
    return (
      <CameraPreviewView
        photo={capturedImage}
        setCapturedImage={setCapturedImage}
        setPreviewVisible={setPreviewVisible}
        setModalVisible={setModalVisible}
        setImage={setImage}
      />
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <Camera
        ref={cameraRef}
        style={styles.camera}
        type={type}
        onCameraReady={setCameraReadyCallback}
        onMountError={onCameraMountFailCallback}
      />
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.button} onPress={toggleCameraType}>
          {/* <Text style={styles.text}>Flip Camera</Text> */}
          <TabBarIconIonic name="camera-reverse" />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={async () => {
            await __takePicture();
          }}
        >
          {/* <Text style={styles.text}>Take Picture</Text> */}
          <TabBarIconIonic name="camera" />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            setModalVisible(false);
          }}
        >
          {/* <Text style={styles.text}>Close</Text> */}
          <TabBarIconIonic name="ios-close-circle" />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

function TabBarIconIonic(props) {
  return (
    <Ionicons
      size={55}
      style={{ marginBottom: -3, color: "white" }}
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    // flexDirection: 'column',
    backgroundColor: "black",
  },
  permissionContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // flexDirection: 'column',
    backgroundColor: "white",
    // color: ''
  },
  camera: {
    flex: 3,
  },
  buttonContainer: {
    // flex: 1,
    backgroundColor: "black",
    flexDirection: "row",
    // backgroundColor: 'transparent',
    // margin: 64,
    // marginTop: '100%',
  },
  button: {
    flex: 1,
    alignSelf: "flex-end",
    alignItems: "center",
  },
  text: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
  },
});
