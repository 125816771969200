import {
  Dimensions,
  Image,
  SafeAreaView,
  Button,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

export const MessageBubble = ({ message }) => {
  return (
    <View style={styles.chatBubble}>
      <Text style={styles.text}> {message}</Text>
      <View style={styles.leftArrow}></View>
      <View style={styles.leftArrowOverlap}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  text: { fontSize: 16, color: "#000", justifyContent: "center" },
  chatBubble: {
    backgroundColor: "#dedede",
    padding: 10,
    borderRadius: 5,
    marginTop: 5,
    marginLeft: "15%",
    maxWidth: "75%",
    alignSelf: "flex-start",
    //maxWidth: 500,
    //padding: 14,

    //alignItems:"center",
    borderRadius: 20,
  },
  leftArrow: {
    position: "absolute",
    backgroundColor: "#dedede",
    //backgroundColor:"red",
    width: 20,
    height: 25,
    bottom: 0,
    borderBottomRightRadius: 25,
    left: -10,
  },

  leftArrowOverlap: {
    position: "absolute",
    backgroundColor: "white",
    //backgroundColor:"green",
    width: 20,
    height: 35,
    bottom: -6,
    borderBottomRightRadius: 18,
    left: -20,
  },
});
