import React, { useState, useEffect } from "react";
import {
  SafeAreaView,
  TextInput,
  ScrollView,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Modal,
  StatusBar,
  Pressable,
} from "react-native";

import { ModalBar } from "../../components/ModalBar";
import { auth, logInWithEmailAndPassword } from "../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch } from "react-redux";
import { clearUser } from "../../features/userDataSlice";

export const LoginModal = ({ modalVisible, setModalVisible }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const dispatch = useDispatch();
  function onCloseTapped() {
    setModalVisible(false);
  }

  function onSignInPressed() {
    logInWithEmailAndPassword(username, password);
  }

  useEffect(() => {
    // console.log(`Are we loading? ${loading}`);
    if (user) {
      // console.log("User is:");
      // console.log(user);
      dispatch(clearUser());
      onCloseTapped();
    }
  }, [user]);
  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={modalVisible}
      onDismiss={onCloseTapped}
    >
      <SafeAreaView style={{ flex: 1 }}>
        <View
          style={{
            flex: 0.5,
            top: 5,
            marginBottom: 5,
          }}
        >
          <ModalBar
            title="Login"
            iconColor="black"
            iconSize="30"
            onPress={onCloseTapped}
          />
        </View>
        <View style={styles.container}>
          <TextInput
            style={styles.input}
            placeholder="Username"
            placeholderTextColor="#aaaaaa"
            onChangeText={(text) => setUsername(text)}
            value={username}
            underlineColorAndroid="transparent"
            autoCapitalize="none"
          />
          <TextInput
            style={styles.input}
            placeholder="Password"
            placeholderTextColor="#aaaaaa"
            onChangeText={(text) => setPassword(text)}
            value={password}
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            secureTextEntry={true}
          />
          <View
            style={{
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={styles.button}
              onPress={() => onSignInPressed()}
            >
              <Text style={styles.buttonTitle}>Login</Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 6,
    backgroundColor: "white",
    alignItems: "center",
    marginTop: StatusBar.currentHeight || 0,
  },
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: "600",
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: "400",
  },
  highlight: {
    fontWeight: "700",
  },
  hstack: {
    flex: 1,
    flexDirection: "row",
  },
  input: {
    height: 48,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
    borderWidth: 1,
  },
  multiLineInput: {
    height: 150,
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 30,
    paddingLeft: 16,
    borderWidth: 1,
  },
  button: {
    backgroundColor: "#4169e1",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    height: "100%",
    width: "100%",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonTitle: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  textLarge: {
    // backgroundColor: 'white',
    fontSize: 20,
    margin: 25,
    alignSelf: "center",
  },
  textMedium: {
    // backgroundColor: 'white',
    fontSize: 15,
    margin: 10,
    alignSelf: "center",
  },
  textSmall: {
    // backgroundColor: 'white',
    fontSize: 10,
    alignSelf: "center",
  },
});
