import React, { useState, useEffect } from "react";
import {
  SafeAreaView,
  ScrollView,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import { InputForm } from "../components/InputForm";

export const ProfileScreen = () => {
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentInsetAdjustmentBehavior="automatic">
        <View>
          <Text style={styles.textLarge}>Construct Your Contact Card</Text>
          <InputForm />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    marginTop: StatusBar.currentHeight || 0,
  },
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: "600",
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: "400",
  },
  highlight: {
    fontWeight: "700",
  },
  hstack: {
    flex: 1,
    flexDirection: "row",
  },
  textLarge: {
    // backgroundColor: 'white',
    fontSize: 20,
    margin: 25,
    alignSelf: "center",
  },
  textMedium: {
    // backgroundColor: 'white',
    fontSize: 15,
    margin: 10,
    alignSelf: "center",
  },
  textSmall: {
    // backgroundColor: 'white',
    fontSize: 10,
    alignSelf: "center",
  },
});
