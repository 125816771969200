import {
  Dimensions,
  Image,
  SafeAreaView,
  Button,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Linking,
} from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import { Shadow } from "react-native-shadow-2";

var { width, height } = Dimensions.get("window");

export const ContactInfo = ({ valentineInfo, onClicked }) => {
  return (
    <View style={{ flex: 1, marginTop: 15 }}>
      <Shadow style={{ backgroundColor: "white" }}>
        <View style={styles.container}>
          <View style={styles.row}>
            <View style={styles.iconView}>
              <Text style={{ fontWeight: "bold" }}>Name:</Text>
            </View>
            <View style={styles.textView}>
              <Text> {valentineInfo.name}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.iconView}>
              <Icon name="instagram" />
            </View>

            <View style={styles.textView}>
              <Text
                styles={styles.contactTextLink}
                onPress={() =>
                  Linking.openURL(
                    `https://www.instagram.com/${valentineInfo.instagram}`
                  )
                }
              >
                {valentineInfo.instagram}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.iconView}>
              <Icon name="phone" />
            </View>
            <View style={styles.textView}>
              <Text styles={styles.contactText}> {valentineInfo.phone}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.iconView}>
              <Icon name="envelope-o" />
            </View>
            <View style={styles.textView}>
              <Text styles={styles.contactText}> {valentineInfo.email}</Text>
            </View>
          </View>
        </View>
      </Shadow>
    </View>
  );
};

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function Icon(props) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

function Icon5(props) {
  return <FontAwesome5 size={30} style={{ marginBottom: -3 }} {...props} />;
}

function IconIonic(props) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

const styles = StyleSheet.create({
  container: {
    width: width * 0.85,
    height: height * 0.45,
    flex: 1,
  },
  text: { fontSize: 16, color: "#000", marginBottom: -3 },
  textView: {
    justifyContent: "center",
    marginLeft: 10,
    flex: 5,
  },
  iconView: {
    flex: 1,
  },
  contactText: {
    fontSize: 25,
    color: "#000",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: -3,
  },
  contactTextLink: {
    fontSize: 25,
    color: "#000",
    textDecorationLine: "underline",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: -3,
  },
  row: { flexDirection: "row", margin: 10 },
});
