import React, { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  SafeAreaView,
  ScrollView,
  FlatList,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  StatusBar,
  RefreshControl,
} from "react-native";

import { ValentineModal } from "../modals/ValentineModal";
import { useSelector, useDispatch } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout, fetchPresentees, fetchValentines } from "../../firebase";
import { PolaroidImage } from "../components/PolaroidImage";
import { loadValentines } from "../features/valentineDataSlice";
import Ionicons from "react-native-vector-icons/Ionicons";

export const PresenteeScreen = ({}) => {
  // const [valentines, setValentines] = useState(null);
  const valentines = useSelector((state) => state.valentinesData);
  const [authedUser, loading, error] = useAuthState(auth);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [valentineInfo, setValentineInfo] = useState(null);
  const [refreshing, setRefreshing] = useState(true);
  const dispatch = useDispatch();
  function onValentineClicked(item) {
    setValentineInfo(item);
    setShowMessageModal(true);
  }

  useEffect(() => {
    // console.log("[Home]: Loading data....");
    // fetchValentines(authedUser.uid, setValentines);
    dispatch(loadValentines(authedUser.uid));
  }, []);

  function renderItem({ item }) {
    return (
      <View style={{ margin: 15 }}>
        <PolaroidImage
          photo={item.image}
          name={item.name}
          phone={item.phone}
          email={item.email}
          insta={item.instagram}
          onImageClicked={() => {
            onValentineClicked(item);
          }}
        />
      </View>
    );
  }

  // console.log("User Data Updated");
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        contentInsetAdjustmentBehavior="automatic"
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        <View
          style={{
            flexDirection: "row",
            // alignContent: "center",
            // justifyContent: "center",
            // borderWidth: 1,
          }}
        >
          <View style={{ flex: 9 }}>
            <Text style={styles.sectionTitle}>Messages</Text>
          </View>
          <View
            style={{
              flex: 1,
              // margin: 10,
              justifyContent: "center",
              // alignSelf: "",
              // borderWidth: 1,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                dispatch(loadValentines(authedUser.uid));
              }}
            >
              <IconIonic name="refresh" />
            </TouchableOpacity>
          </View>
        </View>
        {showMessageModal ? (
          <ValentineModal
            setModalVisible={setShowMessageModal}
            modalVisible={showMessageModal}
            setContent={setValentineInfo}
            valentineInfo={valentineInfo}
          />
        ) : (
          <View />
        )}
        <FlatList
          data={valentines}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                fetchValentines(authedUser.uid, setValentines);
                setRefreshing(false);
              }}
            />
          }
        />
        <Text style={{ alignSelf: "center" }}>
          {!valentines || valentines.length == 0
            ? "No Messages to Display"
            : ""}
        </Text>
      </ScrollView>
    </SafeAreaView>
  );
};

function IconIonic(props) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    marginTop: StatusBar.currentHeight || 0,
  },
  footer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 10,
  },
  pitchContainer: {
    flex: 1,
    justifyContent: "center",
  },
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    alignSelf: "center",
    fontWeight: "600",
    margin: 25,
  },
  sectionAdminTitle: {
    fontSize: 24,
    alignSelf: "center",
    fontWeight: "600",
    margin: 25,
    color: "red",
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: "400",
  },
  highlight: {
    fontWeight: "700",
  },
  hstack: {
    flex: 1,
    flexDirection: "row",
  },
});
